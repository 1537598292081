.dpad {
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -webkit-flex-direction: column;
  -ms-align-items: center;
  -ms-justify-content: center;
  -ms-flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dpadSize {
  height: 500px;
  width: 500px;
}
