.wordcross {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.2s;
}

.correctAnswer {
  color: green;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.8;
  }
  20% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  70% {
    opacity: 0.4;
  }
  80% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.highlight {
  padding: 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  background-color: #bbb;
}

.hint {
  padding: 5px 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  background-color: #bbb;
  font-weight: bold;
  font-size: 30px;
}

.word {
  font-weight: bold;
  font-size: 25px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-3px, -4px) rotate(-3deg);
  }
  20% {
    transform: translate(-5px, 2px) rotate(3deg);
  }
  30% {
    transform: translate(5px, 4px) rotate(0deg);
  }
  40% {
    transform: translate(3px, -3px) rotate(2deg);
  }
  50% {
    transform: translate(-3px, 4px) rotate(-3deg);
  }
  60% {
    transform: translate(-5px, 5px) rotate(2deg);
  }
  70% {
    transform: translate(5px, 3px) rotate(-3deg);
  }
  80% {
    transform: translate(-3px, -3px) rotate(3deg);
  }
  90% {
    transform: translate(3px, 4px) rotate(2deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
