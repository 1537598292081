.arrowAlt {
  width: 70px;
  height: 40px;
  font-size: 40px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.arrow {
  width: 20vh;
  margin: 5vh auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #232b2b;
}

.right {
  margin-left: 0;
  margin-right: 0;
}
.left {
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 0;
}

.up {
  transform: rotate(270deg);
}

.down {
  transform: rotate(90deg);
}

.line {
  width: 300px;
  background: #232b2b;
  height: 2px;
  float: left;
}

.hint-arrow-left {
  display: inline-block;
  border-right: 10px solid #f76902;
  border-bottom: 10px solid #f76902;
  width: 50px;
  height: 50px;
  transform: rotate(-225deg);
  -webkit-animation: soft-blink 0.5s linear infinite;
  -moz-animation: soft-blink 0.5s linear infinite;
  animation: soft-blink 0.5s linear infinite;
}

.hint-arrow-right {
  display: inline-block;
  border-right: 10px solid #f76902;
  border-bottom: 10px solid #f76902;
  width: 50px;
  height: 50px;
  transform: rotate(-45deg);
  -webkit-animation: soft-blink 0.5s linear infinite;
  -moz-animation: soft-blink 0.5s linear infinite;
  animation: soft-blink 0.5s linear infinite;
}

.hint-arrow-up {
  display: inline-block;
  border-right: 10px solid #f76902;
  border-bottom: 10px solid #f76902;
  width: 50px;
  height: 50px;
  transform: rotate(-135deg);
  -webkit-animation: soft-blink 0.5s linear infinite;
  -moz-animation: soft-blink 0.5s linear infinite;
  animation: soft-blink 0.5s linear infinite;
}

.hint-arrow-down {
  display: inline-block;
  border-right: 10px solid #f76902;
  border-bottom: 10px solid #f76902;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  -webkit-animation: soft-blink 0.5s ease infinite;
  -moz-animation: soft-blink 0.5s ease infinite;
  animation: soft-blink 0.5s ease infinite;
}

.highlight-point {
  border-radius: 10px;
  padding-right: 10px;
  padding-left: 10px;
  height: 40px;
}

@-webkit-keyframes soft-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes soft-blink {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}
.point {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 13px solid;
  float: right;
  transform: rotate(90deg);
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  border: 2px solid #232b2b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
