.object {
  animation: createBox 2s infinite;
}
@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.hud {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 100%;
}

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bbb;
  padding-left: 5px;
  padding-right: 5px;
  height: 100%;
  width: 90px;
  color: white;
  font-weight: bold;
  font-size: 25px;
}
